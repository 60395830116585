export enum PageType {
  HOMEPAGE = 'HOME_PAGE',
  LISTING_PAGE = 'LISTING_PAGE',
  PDP_PAGE = 'PRODUCT_PAGE',
  CATEGORY0_PAGE = 'CATEGORY0_PAGE',
  CART = 'CART',
  USER = 'USER',
  ALL_BRANDS = 'ALL_BRANDS',
  BRAND_STORE = 'BRAND_STORE',
  ORDER = 'ORDER',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  FAMILY_PAGE = 'FAMILY_PAGE',
  CANCELLATIONS_AND_RETURNS = 'CANCELLATIONS_AND_RETURNS',
  ALL_CATEGORIES = 'ALL_CATEGORIES',
  FAQ_PAGE = 'FAQ_PAGE',
  GST_BENEFITS = 'GST_BENEFITS',
  REPORT_INFRINGEMENT = 'REPORT_INFRINGEMENT',
  CONTACT_US = 'CONTACT_US',
  ABOUT_US = 'ABOUT_US',
  BULK_DISCOUNT = 'BULK_DISCOUNT',
  IB_CREDIT = 'IB_CREDIT',
}
